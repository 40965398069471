import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // user has logged in..
        //console.log(authUser);
        setCurrentUser(authUser);
      } else {
        // user has logged out..
        setCurrentUser(null);
      }
      setCurrentUser(authUser);
    });

    return () => {
      //perform some cleanup actions
      unsubscribe();
    };
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
