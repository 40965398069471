import React, { useState, useContext } from "react";
import { AuthContext } from "../../Auth";

import { Expert1 } from "../../images";
import { SectionBanner } from "../../globalStyles";

import { FaTimes, FaBars } from "react-icons/fa";
import {
  Header,
  Logo,
  ResponsiveIcon,
  Navigation,
  NavBarHashLink,
  NavBarLink,
  ImagUser,
} from "./Navbar.elements";

const Navbar = ({ user }) => {
  const { currentUser } = useContext(AuthContext);
  const [styckyheader, setStyckyheader] = useState(false);
  const [click, setClick] = useState(false);

  const ShowResponsiveBar = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showHeaderScroll = () => {
    if (window.scrollY > 0) {
      setStyckyheader(true);
    } else {
      setStyckyheader(false);
    }
    // console.log(window.scrollY);
  };

  window.addEventListener("scroll", showHeaderScroll);

  return (
    <>
      <SectionBanner styckyheader={styckyheader} />
      <Header styckyheader={styckyheader}>
        <Logo href="#" styckyheader={styckyheader} onClick={closeMobileMenu}>
          CryptoWeb <span>.{user}</span>
        </Logo>

        <ResponsiveIcon onClick={ShowResponsiveBar}>
          {click ? <FaTimes /> : <FaBars />}
        </ResponsiveIcon>

        <Navigation onClick={ShowResponsiveBar} click={click}>
          <NavBarHashLink styckyheader={styckyheader} smooth to="/#banner">
            Home
          </NavBarHashLink>

          {currentUser ? (
            <>
              <NavBarLink styckyheader={styckyheader} to="/profile">
                <ImagUser>
                  <img alt="profile" src={Expert1} />
                </ImagUser>
              </NavBarLink>
            </>
          ) : (
            <>
              <NavBarLink styckyheader={styckyheader} to="/login">
                Sign Up
              </NavBarLink>
            </>
          )}
        </Navigation>
      </Header>
    </>
  );
};

export default Navbar;
