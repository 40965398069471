import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  FaRegTrashAlt,
  FaSave,
  FaBars,
  FaTimes,
  FaArrowUp,
  FaArrowDown,
  FaBolt,
} from "react-icons/fa";

import { SimulatorCoin } from "../../components";

const GridListCoinSelected = ({
  key,
  coin,
  image,
  name,
  price,
  priceChange,
  deleteCoin,
  posts,
}) => {
  const [showmore, setShowmore] = useState(false);
  const handlerShowmore = () => setShowmore(!showmore);
  return (
    <CoinContainer showmore={showmore}>
      <CoinRow>
        <img src={image} alt="crypto" />
        <CoinName>{name}</CoinName>
        <CoinPrice>€{price}</CoinPrice>
        <ArrowSymb percent={priceChange}>
          <span>{priceChange < 0 ? <FaArrowDown /> : <FaArrowUp />}</span>
        </ArrowSymb>
        <CoinPercent percent={priceChange}>
          <p>{priceChange.toFixed(2)}%</p>
        </CoinPercent>

        <CoinButton>
          {/*} <DeleteSelect
            onClick={(e) => {
              deleteCoin(coin.id);
            }}
          />*/}
          <ShowSimulatorArea onClick={handlerShowmore}>
            {showmore ? <FaTimes /> : <FaBars />}
          </ShowSimulatorArea>

          <DisplaySimulatorArea Link to={`/simulator/${coin.id}`}>
            <FaBolt />
          </DisplaySimulatorArea>
        </CoinButton>
      </CoinRow>

      <DisplaySimulator showmore={showmore}>
        <Titulo>Simular Compra </Titulo>
        <SimulatorCoin posts={posts} coin={coin.id} Actualprice={price} />
      </DisplaySimulator>
    </CoinContainer>
  );
};

export default GridListCoinSelected;

const CoinContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  background-image: linear-gradient(
    -225deg,
    #e71b58 0%,
    #111 48%,
    #fa044e 100%
  );
  color: #fff;
  width: 100%;
  height: ${({ showmore }) => (showmore ? "auto" : "100px")};

  :hover {
    background: #111;
    cursor: pointer;
  }

  @media screen and (max-width: 691px) {
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const CoinRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid #d7d7d7;
  width: 100%;

  img {
    height: 30px;
    width: 30px;
    margin-left: 20px;
    //margin-right: 20px;
  }
  @media screen and (max-width: 691px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
`;

const DisplaySimulator = styled.div`
  display: ${({ showmore }) => (showmore ? "block" : "none")};
  padding: 10px;
  background: #ebe8eb;
`;

const Titulo = styled.div`
  width: 100%;
  height: 40px;
  padding-top: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #000000;
`;

const CoinName = styled.div`
  font-size: 15px;
  width: 100px;
  text-transform: uppercase;
`;

const CoinPrice = styled.div`
  width: 10px;
  margin-left: 10px;
`;

const ArrowSymb = styled.div`
  width: 1px;
  color: ${({ percent }) => (percent > 0 ? "#11d911" : "#f00606")};
  margin-left: 120px;
`;

const CoinPercent = styled.div`
  width: 10px;
  margin-left: 20px;

  p {
    color: ${({ percent }) => (percent > 0 ? "#11d911" : "#f00606")};
  }
`;

const CoinButton = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  margin-left: 50px;

  @media screen and (max-width: 691px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const DeleteSelect = styled(FaRegTrashAlt)`
  cursor: pointer;
  margin-left: 10px;
`;

const DisplaySimulatorArea = styled(Link)`
  cursor: pointer;
  margin-left: 15px;
  color: #fff;
`;

const ShowSimulatorArea = styled.a`
  cursor: pointer;
  margin-left: 15px;
`;
