import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  RenderIsLoading,
  SectionSimulator,
  TitleSimulator,
} from "../../globalStyles";

import { HistoryChart } from "../../components";
import coinGecko from "../../apis/Getcointogecko";

const SimulatorPage = () => {
  const { id } = useParams();
  const [coinData, setCoinData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const formatData = (data) => {
    return data.map((el) => {
      return {
        t: el[0],
        y: el[1].toFixed(2),
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const [day, week, year, detail] = await Promise.all([
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: "eur",
            days: "1",
          },
        }),
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: "eur",
            days: "7",
          },
        }),
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: "eur",
            days: "365",
          },
        }),
        coinGecko.get("/coins/markets", {
          params: {
            vs_currency: "eur",
            //ids: "bitcoin,ethereum",
            ids: id,
          },
        }),
      ]);

      //console.log(results.data);
      setCoinData({
        day: formatData(day.data.prices),
        week: formatData(week.data.prices),
        year: formatData(year.data.prices),
        detail: detail.data[0],
      });
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const renderCoins = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
  };

  return (
    <>
      <SectionSimulator>
        <TitleSimulator>
          Simulator <span> {id} </span>
        </TitleSimulator>
        <RenderIsLoading>{renderCoins()}</RenderIsLoading>

        <HistoryChart data={coinData} />
      </SectionSimulator>
    </>
  );
};

export default SimulatorPage;
