import React, { useCallback, useState } from "react";
import { withRouter } from "react-router";
import { auth } from "../../firebase";

import { Expert1 } from "../../images";

import {
  SectionBanner,
  LoginBox,
  ImagUser,
  TextLink,
  FormLogin,
  LinkRegister,
  DisplayError,
} from "./auth.styles";

const Signup2 = ({ history }) => {
  const [error, setError] = useState("");
  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      setError("");
      try {
        await auth.createUserWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        //alert(error);
        setError(error.message);
      }
    },
    [history]
  );

  return (
    <SectionBanner>
      {error ? (
        <DisplayError>
          <span>{error}</span>
        </DisplayError>
      ) : (
        <></>
      )}
      <LoginBox>
        <ImagUser>
          <img alt="" src={Expert1} />
        </ImagUser>
        <h3>Sign Up Here </h3>

        <FormLogin>
          <form onSubmit={handleSignUp}>
            <input name="email" type="email" placeholder="Your Email" />
            <input
              name="password"
              type="password"
              placeholder="Your Password"
            />
            <button type="submit">Sign up</button>
          </form>
        </FormLogin>

        <TextLink>
          <LinkRegister color={"#ff0157"} to="/login">
            Login
          </LinkRegister>
          <LinkRegister color={"#fff"}>Forget password?</LinkRegister>
        </TextLink>
      </LoginBox>
    </SectionBanner>
  );
};

export default withRouter(Signup2);
