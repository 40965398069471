import React, { useState, useContext } from "react";

import styled from "styled-components";
import { FaSave } from "react-icons/fa";
import firebase from "firebase";
import { auth, db } from "../../firebase";
import { AuthContext } from "../../Auth";

const SimulatorCoin = ({ posts, coin, Actualprice }) => {
  const { currentUser } = useContext(AuthContext);
  const [buy, setBuy] = useState(0);

  const updateCoinToDB = (postId) => (event) => {
    const timestamp = Date.now(); // This would be the timestamp you want to format
    event.preventDefault();
    if (buy > 0) {
      const valoreuros = buy;
      const precoinicialcompra = Actualprice.toFixed(6);
      const moedasCompradas = valoreuros / precoinicialcompra;
      const totalGanho = moedasCompradas * precoinicialcompra;

      //console.log("dentro de addCoinToDB ",buy,valoreuros,coin,Actualprice,precoinicialcompra,totalGanho,value.id);

      if (postId.id) {
        db.collection("cryptocoin").doc(postId.id).update({
          name: coin,
          currentprice: precoinicialcompra,
          totalcoinbuy: moedasCompradas,
          valoreuros: buy,
          username: currentUser.email,
          timestamp: timestamp, //firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    }
  };

  const deleteCoinToDB = (postId) => (event) => {
    event.preventDefault();
    //console.log("dentro de addCoinToDB ",buy,valoreuros,coin,Actualprice,precoinicialcompra,totalGanho,value.id);

    if (postId.id) {
      db.collection("cryptocoin").doc(postId.id).delete({});
    }
  };

  const renderPrice = ({ pricecoin, namecoin, posts }) => {
    const valoreuros = buy;
    const precoinicialcompra = pricecoin.toFixed(2);
    const moedasCompradas = valoreuros / precoinicialcompra;
    const totalGanho = moedasCompradas * precoinicialcompra;
    //console.log("Posts es = ", { posts });
    return (
      <>
        <span>Moedas:{moedasCompradas.toFixed(8)} </span>
      </>
    );
  };

  return (
    <CoinData>
      <SectionInfobuy>
        <form>
          <input
            type="text"
            placeholder="buy"
            value={buy}
            onChange={(e) => setBuy(e.target.value)}
          />
        </form>
        {renderPrice({ pricecoin: Actualprice, namecoin: coin, posts })}
      </SectionInfobuy>
      {posts
        .filter((post) => post.post.name === coin)
        .map((postfiltered) => (
          <>
            <SectionDB>
              <span>
                Data Compra:{" "}
                {new Intl.DateTimeFormat("UTC", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }).format(postfiltered.post.timestamp)}
              </span>
              <span>Price: €{postfiltered.post.currentprice}</span>
              <span>
                Coin Buyed: {postfiltered.post.totalcoinbuy.toFixed(8)}
              </span>
              <span>Inves: {postfiltered.post.valoreuros}</span>
              <ColorText
                percent={
                  postfiltered.post.totalcoinbuy.toFixed(8) * Actualprice -
                  postfiltered.post.valoreuros
                }
              >
                <span>
                  Diff:{" "}
                  {(
                    postfiltered.post.totalcoinbuy.toFixed(8) * Actualprice
                  ).toFixed(2)}
                </span>
              </ColorText>
              <button onClick={updateCoinToDB(postfiltered)}>update</button>
              <button onClick={deleteCoinToDB(postfiltered)}>delete</button>
            </SectionDB>
          </>
        ))}
    </CoinData>
  );
};

export default SimulatorCoin;

const CoinData = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #000;
  background-image: linear-gradient(-225deg, #fff 0%, #fff 48%, #fff 100%);
  border: 1px solid #000;
  height: auto;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 691px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    padding-top: 10px;

    span {
      margin-left: 5px;
      margin-right: 5px;
      justify-content: left;
    }
  }
`;

const SectionInfobuy = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-right: 10px;
  form {
    width: 100%;
  }
  input {
    margin-left: 50px;
    width: 100px;
  }
  span {
    margin-left: 25px;
    margin-right: 25px;
  }

  @media screen and (max-width: 691px) {
  }
`;

const SectionDB = styled.div`
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 5px;
`;

const ColorText = styled.div`
  color: ${({ percent }) => (percent > -0.01 ? "#11d911" : "#f00606")};
`;

/* console.log(
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp)
    );*/
