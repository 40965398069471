import React from "react";
import styled from "styled-components";

const CoinListHome = ({
  image,
  name,
  symbol,
  price,
  volume,
  priceChange,
  marketcap,
  symbollocal,
}) => {
  return (
    <CoinContainer>
      <CoinRow>
        <CoinTitle>
          <img src={image} alt="crypto" />
          <h1>{name}</h1>
          <p>{symbol}</p>
        </CoinTitle>

        <CoinPrice>€{price}</CoinPrice>
        <CoinPercent percent={priceChange}>
          <p>{priceChange.toFixed(2)}%</p>
        </CoinPercent>

        {volume === null ? (
          <CoinVolume>
            {symbollocal}
            {volume}
          </CoinVolume>
        ) : (
          <CoinVolume>
            {symbollocal}
            {volume.toLocaleString()}
          </CoinVolume>
        )}

        {marketcap === null ? (
          <CoinMarket>
            {symbollocal}
            {marketcap}
          </CoinMarket>
        ) : (
          <CoinMarket>
            Mkt Cap: {symbollocal}
            {marketcap.toLocaleString()}
          </CoinMarket>
        )}
      </CoinRow>
    </CoinContainer>
  );
};

export default CoinListHome;

const CoinContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 691px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const CoinRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: start;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #d7d7d7;
  width: 900px;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    margin-bottom: 15px;
    font-size: 0.9rem;
    height: 120px;
    width: 400px;
  }
`;
const CoinTitle = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
  min-width: 300px;
  h1 {
    font-size: 1rem;
    width: 150px;
  }
  img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  p {
    text-transform: uppercase;
  }
`;

const CoinPrice = styled.p`
  width: 110px;
`;
const CoinVolume = styled.p`
  width: 200px;
`;

const CoinMarket = styled.p`
  width: 240px;
`;

const CoinPercent = styled.div`
  width: 80px;
  p {
    color: ${({ percent }) => (percent > 0 ? "#11d911" : "#f00606")};
  }
`;
