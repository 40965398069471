import React from "react";
import styled from "styled-components";

const CopyRightText = styled.div`
  padding: 8px 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  p {
    color: #333;
  }
  a {
    color: #ff0157;
    font-weight: 600;
    text-decoration: none;
  }
`;

const Footer = () => {
  return (
    <CopyRightText>
      <p>
        Copyright 2021 <a href="#">TuaWebmobile.com</a> All Right Reserved
      </p>
    </CopyRightText>
  );
};

export default Footer;
