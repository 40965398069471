import React, { useRef, useEffect, useState, useContext } from "react";
import moment from "moment";
import firebase from "firebase";
import Chartjs from "chart.js";
import styled from "styled-components";
import { Chartoptions } from "../ChartData/ChartConfigs";
import { FaSave } from "react-icons/fa";
import { db } from "../../firebase";
import { AuthContext } from "../../Auth";

const HistoryChart = ({ data }) => {
  const chartRef = useRef();
  const { day, week, year, detail } = data;
  const [timeFormat, setTimeFormat] = useState("24h");
  const [buy, setBuy] = useState(100);
  const { currentUser } = useContext(AuthContext);
  const [coin, setCoin] = useState([]);

  const selectTimeFormat = () => {
    switch (timeFormat) {
      case "24h":
        return day;
      case "7d":
        return week;
      case "1y":
        return year;
      default:
        return day;
    }
  };

  useEffect(() => {
    if (chartRef && chartRef.current && detail) {
      const chartInstance = new Chartjs(chartRef.current, {
        type: "line",
        data: {
          datasets: [
            {
              label: `${detail.name} price`,
              data: selectTimeFormat(),
              backgroundColor: "rgba(144, 228, 176, 0.904)",
              borderColor: "#ffffff",
              pointRadius: 0,
              borderWidth: 1,
            },
          ],
        },
        options: {
          ...Chartoptions,
        },
      });
    }
  });

  const addCoinToDB = (coin) => (event) => {
    const timestamp = Date.now(); // This would be the timestamp you want to format

    event.preventDefault();
    if (buy > 0) {
      //console.log("dentro de addCoinToDB ", buy, coin, currentUser.email);

      const valoreuros = buy;
      const precoinicialcompra = coin.current_price.toFixed(6);
      const moedasCompradas = valoreuros / precoinicialcompra;
      const totalGanho = moedasCompradas * precoinicialcompra;

      db.collection("cryptocoin").add({
        name: coin.id,
        currentprice: precoinicialcompra,
        totalcoinbuy: moedasCompradas,
        valoreuros: buy,
        username: currentUser.email,
        timestamp: timestamp,
      });
    }
  };

  const renderPrice = () => {
    if (detail && buy) {
      /**Guardar base dados */
      const valoreuros = buy;
      const moedasCompradas = valoreuros / detail.current_price.toFixed(2);
      const precoinicialcompra = detail.current_price.toFixed(2);
      const simularCompra = moedasCompradas.toFixed(8);
      const totalGanho = simularCompra * detail.current_price.toFixed(2);

      return (
        <SectionRenderPrice>
          <SectionRow>
            <SectionCol>
              <span>
                <img src={detail.image} alt="crypto"></img>
              </span>
              <p>
                Actualizaçao:{" "}
                {moment(detail.last_updated).format("MMMM Do YYYY, h:mm:ss a")}
              </p>
              <p>hight 24H: {detail.high_24h}</p>
              <p>low 24H: {detail.low_24h}</p>
              <p valor={detail.current_price.toFixed(2)}>
                Current Price: €{detail.current_price.toFixed(2)}
              </p>
              <p>
                Price Change 24h:
                <TextPrice valor={detail.price_change_24h.toFixed(2)}>
                  €{detail.price_change_24h.toFixed(2)}
                </TextPrice>
              </p>
            </SectionCol>
          </SectionRow>
          <SectionRow>
            <SectionCol>
              <p>Simular: Comprar €{valoreuros}</p>
              <p>Total Moedas: {simularCompra}</p>
              <p>
                Total Ganho:{" "}
                <TextPrice valor={totalGanho}>
                  €{totalGanho.toFixed(2)}
                </TextPrice>
              </p>
              <button onClick={addCoinToDB(detail)}>save</button>
            </SectionCol>
          </SectionRow>
        </SectionRenderPrice>
      );
    }
  };

  return (
    <>
      <div>
        <form>
          <input
            type="text"
            placeholder="buy"
            value={buy}
            onChange={(e) => setBuy(e.target.value)}
          />
        </form>
      </div>
      {renderPrice()}
      <ChartSection>
        <div>
          <canvas ref={chartRef} id="myChart" width={500} height={300}></canvas>
        </div>
        <ButtonTimeSelect>
          <button onClick={() => setTimeFormat("24h")}>24h</button>
          <button onClick={() => setTimeFormat("7d")}>7d</button>
          <button onClick={() => setTimeFormat("1y")}>1 year</button>
        </ButtonTimeSelect>
      </ChartSection>
    </>
  );
};

export default HistoryChart;

const SectionRenderPrice = styled.div`
  margin-top: 30px;
  width: 80%;
  background: #fff;
  color: #111;
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const SectionRow = styled.div`
  flex-direction: row;
`;
const SectionCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    // width: 180px;
    //margin-top: 2px;
    //margin-left: 2px;
    margin-bottom: 5px;
  }
  img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
`;

const TextPrice = styled.span`
  color: ${({ valor }) => (valor > 0 ? "#11d911" : "#f00606")};
`;

const ButtonTimeSelect = styled.div`
  display: flex;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
    padding: 2px;
  }
`;

const ChartSection = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  width: 80%;
  background: #fff;

  @media screen and (max-width: 691px) {
    margin-left: 1px;
    left: 50px;
    max-width: 550px;
  }
`;
