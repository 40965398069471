// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDKinSR2NsGZYZpItbLx_n3FHV8b9MjDDw",
  authDomain: "cryptoweb-74947.firebaseapp.com",
  projectId: "cryptoweb-74947",
  storageBucket: "cryptoweb-74947.appspot.com",
  messagingSenderId: "969932973944",
  appId: "1:969932973944:web:50e842068a18b2754a4a8d",
  measurementId: "G-DFE6W8CZSR",
});

//export default db;
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export { db, auth, storage };
