import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import { BG } from "./images";

const GlobalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    //scroll-behavior: smooth;
}
`;

export const Container = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  color: #fff;

  //padding: 20px 60px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background: linear-gradient(-225deg, #201e1e 0%, #111 48%, #121 100%);

  @media screen and (max-width: 691px) {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const SectionBanner = styled.div`
  position: relative;
  width: 100%;
  height: ${({ styckyheader }) => (styckyheader ? "0" : "15vh")};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111;
`;

export const RenderIsLoading = styled.div`
  margin: 10px;
`;

/** HOME */

export const Search = styled.div`
  margin-top: 100px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    padding-left: 16px;
    width: 300px;
    height: 50px;
    border-radius: 4px;
    border: none;
    background-image: linear-gradient(-225deg, #fff 0%, #fff 48%, #fff 100%);
    color: #000;
    text-align: center;

    ::placeholder {
      color: #111;
    }
  }

  @media screen and (max-width: 991px) {
  }
`;

export const ContainerCoin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TabelaTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: justify;
  height: 80px;

  width: 100%;
  h1 {
    font-size: 1rem;
    margin-right: 120px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

/** PROFILES */

export const TitlePage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding: 10px;
  color: #fff;
  @media screen and (max-width: 691px) {
    position: absolute;
    left: 5px;
    max-width: 550px;
    width: 99%;
  }
`;

export const LinkRegister = styled(Link)`
  color: ${(props) => props.color};
  text-decoration: none;
`;

/**SIMULATION */

export const SectionSimulator = styled.div`
  display: flex;

  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  align-items: center;

  background: linear-gradient(-225deg, #201e1e 0%, #111 48%, #121 100%);

  color: #fff;
`;

export const TitleSimulator = styled.div`
  width: 100%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  span {
    margin-left: 10px;
    color: #ff0157;
  }
`;

export default GlobalStyle;
