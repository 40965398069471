import React, { useState, useContext } from "react";
import styled from "styled-components";
import { FaBars, FaTimes, FaArrowUp, FaArrowDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { WatchListContext } from "../Coin/WatchListContext";

const SelectCoinMenu = ({ data, defaultcoin }) => {
  const [showmore, setShowmore] = useState(false);
  const [search, setSearch] = useState("");

  const handlerShowmore = () => setShowmore(!showmore);

  const { addCoin } = useContext(WatchListContext);
  const handleClick = (coin) => {
    addCoin(coin);
  };

  const filtercoin = data.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const filtercoindefault = defaultcoin.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <AllCoin showmore={showmore}>
      <HeaderSelect>
        <ButtonShow onClick={handlerShowmore}>
          {showmore ? <FaTimes /> : <FaBars />}
        </ButtonShow>

        <Search>
          <form>
            <input
              type="text"
              placeholder="Search here for your cryptocurrency "
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </Search>
      </HeaderSelect>

      <SectionAllCoin>
        {showmore ? (
          <>
            {filtercoin.map((coin) => {
              return (
                <Card
                  showmore={showmore}
                  percent={coin.price_change_percentage_24h}
                >
                  <CardContainer onClick={() => handleClick(coin.id)}>
                    <CardHead>
                      <img src={coin.image} alt="" />
                      <TextSymbol>{coin.symbol}</TextSymbol>
                      <ArrowSymb percent={coin.price_change_percentage_24h}>
                        {coin.price_change_percentage_24h < 0 ? (
                          <FaArrowDown />
                        ) : (
                          <FaArrowUp />
                        )}
                      </ArrowSymb>
                    </CardHead>
                    <CardContext>€{coin.current_price}</CardContext>
                  </CardContainer>
                </Card>
              );
            })}
          </>
        ) : (
          <>
            {filtercoindefault.map((coin) => {
              return (
                <Card
                  showmore={showmore}
                  percent={coin.price_change_percentage_24h}
                >
                  <CardContainer onClick={() => handleClick(coin.id)}>
                    <CardHead>
                      <img src={coin.image} alt="" />
                      <TextSymbol>{coin.symbol}</TextSymbol>
                      <ArrowSymb percent={coin.price_change_percentage_24h}>
                        {coin.price_change_percentage_24h < 0 ? (
                          <FaArrowDown />
                        ) : (
                          <FaArrowUp />
                        )}
                      </ArrowSymb>
                    </CardHead>
                    <CardContext>€{coin.current_price}</CardContext>
                  </CardContainer>
                </Card>
              );
            })}
          </>
        )}
      </SectionAllCoin>
    </AllCoin>
  );
};

export default SelectCoinMenu;

const AllCoin = styled.div`
  display: flex;
  color: #000;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-image: linear-gradient(
    -225deg,
    #e71b58 0%,
    #e71b58 48%,
    #121 100%
  );
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  //border-radius: 5px;
  @media screen and (max-width: 691px) {
    margin-top: 60px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

const SectionAllCoin = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const HeaderSelect = styled.div`
  display: flex;
  font-size: 1rem;
  justify-content: center;
`;

const ButtonShow = styled.div`
  position: absolute;
  left: 60px;
  cursor: pointer;
  color: #fff;

  @media screen and (max-width: 691px) {
    left: 20px;
  }
`;

const Search = styled.div`
  margin-top: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 15px;
  font-size: 0.5rem;

  input {
    padding-left: 16px;
    width: 350px;
    height: 50px;
    box-sizing: border-box;
    border: transparent;
    border-bottom: 1px solid #fff;
    font-size: 16px;
    color: #111;
    background-color: transparent;
    background-position: right 5px center;
    padding: 10px 30px 10px 10px;
    transition: width 0.4s ease-in-out;
    text-align: center;
    color: #e2e2e2;
    ::placeholder {
      color: #fff;
    }
  }
`;

const Card = styled.div`
  width: ${({ showmore }) => (showmore ? "100px" : "150px")};
  //width: 100px;
  //height: 60px;
  height: ${({ showmore }) => (showmore ? "60px" : "80px")};
  margin: 5px;
  //background: #fff;
  background: ${({ percent }) => (percent > 0 ? "#9dec9d" : "#fff")};
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: #f3e7e7;
    //background: ${({ percent }) => (percent > 0 ? "#9dec9d" : "#ffbfbf")};
  }
`;

const CardHead = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`;

const CardContext = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px;
`;

const TextSymbol = styled.span`
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const ArrowSymb = styled.div`
  color: ${({ percent }) => (percent > 0 ? "#11d911" : "#f00606")};
  font-size: 0.8rem;
`;
