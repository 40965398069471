import React, { useContext, useEffect, useState } from "react";

import { CoinListHome } from "../../components";

import coinGecko from "../../apis/Getcointogecko";

import {
  RenderIsLoading,
  Search,
  ContainerCoin,
  CoinListGroup,
  TabelaTitle,
} from "../../globalStyles";

const HomePage = () => {
  const symbollocal = "€";
  const [coins, setCoins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response2 = await coinGecko.get("/coins/markets", {
        params: {
          vs_currency: "eur",
        },
      });

      setCoins(response2.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const filteredCoins = coins.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const renderCoins = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
  };

  return (
    <>
      <Search>
        <form>
          <input
            type="text"
            placeholder="Search your cryptocurrency"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </Search>

      <ContainerCoin>
        <TabelaTitle>
          <h1>Coin</h1>
          <h1>symbol</h1>
          <h1>price</h1>
          <h1>volume</h1>
          <h1>marketcap</h1>
        </TabelaTitle>

        <RenderIsLoading>{renderCoins()}</RenderIsLoading>
        {filteredCoins.map((coin) => {
          return (
            <CoinListHome
              key={coin.id}
              image={coin.image}
              name={coin.name}
              symbol={coin.symbol}
              price={coin.current_price}
              marketcap={coin.market_cap}
              priceChange={coin.price_change_percentage_24h}
              volume={coin.total_volume}
              symbollocal={symbollocal}
            />
          );
        })}
      </ContainerCoin>
    </>
  );
};

export default HomePage;
