import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";

const PrivateRouter = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  //console.log("Ola sou o user " + currentUser);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (currentUser) {
          return <RouteComponent {...routeProps} />;
        } else {
          <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

export default PrivateRouter;
