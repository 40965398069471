import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Header = styled.div`
  position: ${({ styckyheader }) => (styckyheader ? "sticky" : "fixed")};
  background: ${({ styckyheader }) => (styckyheader ? "#fff" : "none")};
  box-shadow: ${({ styckyheader }) =>
    styckyheader ? "none" : "0 5px 20px rgba(0, 0, 0, 0.05)"};
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;

  @media screen and (max-width: 991px) {
    padding: 10px 20px;
  }
`;

export const Logo = styled.a`
  //color: #fff;
  color: ${({ styckyheader }) => (styckyheader ? "#111" : "#fff")};
  font-weight: 700;
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;
  span {
    color: #ff0157;
  }
`;

export const ResponsiveIcon = styled.div`
  display: none;
  color: #ff0157;

  @media screen and (max-width: 991px) {
    display: block;
    transform: translate(-10%, 10%);
    font-size: 2rem;
    cursor: pointer;
  }
`;

export const Navigation = styled.ul`
  position: relative;
  display: flex;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 68px);
    position: fixed;
    top: 68px;
    left: 0;
    /** variavel control click aparece o menu de lado esquerdo
            <NavMenu onClick={handleClick} click={click}>
    */
    left: ${({ click }) => (click ? 0 : "-110%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #ff1057;
    justify-content: center;
    align-items: center;
  }
`;

export const NavLink = styled.li`
  list-style: none;
  margin-left: 30px;

  a {
    text-decoration: none;
    color: ${({ styckyheader }) => (styckyheader ? "#111" : "#fff")};
    font-weight: 300;
    cursor: pointer;
    :hover {
      color: #ff0157;
    }
  }
  @media screen and (max-width: 991px) {
    text-align: center;
    margin-left: 0px;
    padding: 2rem;
    width: 100%;
    display: table;

    a {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 300;
      :hover {
        color: #f0e9eb;
      }
    }
  }
`;

export const NavBarLink = styled(Link)`
  list-style: none;
  margin-left: 30px;
  text-decoration: none;
  color: ${({ styckyheader }) => (styckyheader ? "#111" : "#fff")};
  font-weight: 300;

  cursor: pointer;
  :hover {
    color: #ff0157;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
    margin-left: 0px;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 300;
    :hover {
      color: #f0e9eb;
    }
  }
`;

export const NavBarHashLink = styled(HashLink)`
  list-style: none;
  margin-left: 30px;
  text-decoration: none;
  color: ${({ styckyheader }) => (styckyheader ? "#111" : "#fff")};
  font-weight: 300;
  cursor: pointer;
  :hover {
    color: #ff0157;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
    margin-left: 0px;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 300;
    :hover {
      color: #f0e9eb;
    }
  }
`;

export const ImagUser = styled.div`
  align-items: center;
  justify-content: center;
  top: -7px;
  position: relative;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
