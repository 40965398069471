import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthProvider } from "./Auth";
import PrivateRouter from "./PrivateRouter";
import GlobalStyle, { Container } from "./globalStyles";

import { Navbar, Footer, Signup2, Login2 } from "./components";
import { HomePage, ProfilePage, SimulatorPage } from "./pages";
import { WatchListContextProvider } from "./components/Coin/WatchListContext";

function App() {
  return (
    <AuthProvider>
      <WatchListContextProvider>
        <Router>
          <GlobalStyle />

          <Navbar />
          <Container>
            <Switch>
              /** Authentication */
              <Route path="/login" component={Login2} />
              <Route path="/signup" component={Signup2} />
              /**Section */
              <Route path="/" exact component={HomePage} />
              /**Page Private*/
              <PrivateRouter path="/profile" component={ProfilePage} />
              <PrivateRouter
                path="/simulator/:id"
                exact
                component={SimulatorPage}
              />
            </Switch>
          </Container>
          <Footer />
        </Router>
      </WatchListContextProvider>
    </AuthProvider>
  );
}

export default App;
