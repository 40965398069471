import React, { useState, useContext, useEffect } from "react";

import { auth, db } from "../../firebase";
import { AuthContext } from "../../Auth";

import { withRouter } from "react-router";

import {
  TitlePage,
  LinkRegister,
  ListCoinSelect,
  CoinListGroup,
  RenderIsLoading,
} from "../../globalStyles";

import { SelectCoinMenu, GridListCoinSelected } from "../../components";
import coinGecko from "../../apis/Getcointogecko";
import { WatchListContext } from "../../components/Coin/WatchListContext";

const ProfilePage = () => {
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [coins, setCoins] = useState([]);
  const [listcoins, setListCoins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { watchList, deleteCoin, addCoin } = useContext(WatchListContext);
  const [posts, setPosts] = useState([]);

  async function handleLogout() {
    setError("");
    try {
      await auth.signOut();
    } catch {
      setError("Faild to Log Out");
    }
  }

  /**DATA BASE */

  useEffect(() => {
    db.collection("cryptocoin")
      .where("username", "==", currentUser.email)
      .onSnapshot((snapshot) => {
        //every time a new post is added, this code firebase refresh
        setPosts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            post: doc.data(),
          }))
        );
      });
  }, []);

  const GetCoinApi = async () => {
    setIsLoading(true);
    const response2 = await coinGecko.get("/coins/markets", {
      params: {
        vs_currency: "eur",
      },
    });
    setListCoins(response2.data);
    setIsLoading(false);
  };

  const renderdbCoin = () => {
    if (posts) {
      posts.map((coin) => {
        addCoin(coin.post.name);
      });
      console.log("A: ", posts);
    }
  };

  const renderCoins = () => {
    if (isLoading) {
      return (
        <>
          <div>Loading... </div>
          {renderdbCoin()}
        </>
      );
    }
  };

  useEffect(() => {
    //console.log("currentUser", currentUser.email);

    const fetchData = async () => {
      setIsLoading(true);

      const response = await coinGecko.get("/coins/markets", {
        params: {
          vs_currency: "eur",
          //ids: "bitcoin,ethereum",
          ids: watchList.join(","),
        },
      });
      setCoins(response.data);
      setIsLoading(false);
    };

    if (watchList.length > 0) {
      fetchData();
      GetCoinApi();
      //renderdbCoin();
    } else {
      setCoins([]);
      GetCoinApi();
      //renderdbCoin();
    }
  }, [watchList]);

  return (
    <>
      <TitlePage>
        Welcome <span>{currentUser.email} </span>
        <LinkRegister color={"#ff0157"} to="/" onClick={handleLogout}>
          Log Out
        </LinkRegister>
      </TitlePage>
      <RenderIsLoading>
        {renderCoins()}
        {renderdbCoin()}
      </RenderIsLoading>

      <SelectCoinMenu data={listcoins} defaultcoin={coins} />

      {coins.map((coin) => {
        return (
          <GridListCoinSelected
            key={coin.id}
            coin={coin}
            image={coin.image}
            name={coin.name}
            symbol={coin.symbol}
            price={coin.current_price}
            marketcap={coin.market_cap}
            priceChange={coin.price_change_percentage_24h}
            volume={coin.total_volume}
            deleteCoin={deleteCoin}
            posts={posts}
          />
        );
      })}
    </>
  );
};

export default withRouter(ProfilePage);
